<template>
    <div>
        <el-container class="content">
            <template v-for="(type,index) in qualificationGroup">
                <el-card>
                    <div slot="header" class="clearfix" :key="index">
                        <div class="type-title">
                            <template v-if="type._id === 1">
                                <span class="mustIcon">*</span>
                            </template>
                            <span>{{ type.type }}</span>
                        </div>
                    </div>
                    <div class="uploadQualification">
                        <el-row :gutter="20">
                            <template v-for="(item,index) in type.qualificationList">
                                <el-col :span="24">
                                    <div style="display: flex;align-items: center;gap: 40px">
                                        <div class="qualificationUploadItem">
                                            <el-upload
                                                class="avatar-uploader"
                                                :action="$config.baseUrl + 'api/i/image'"
                                                :on-success="(res, file)=>{
                                                return handleAvatarSuccess(res,file,item,index)
                                            }"
                                                :on-remove="(file, fileList) => {
                                            return handleRemove(file, fileList,item)
                                            }"
                                               :before-upload="(file) => beforeAvatarUpload(file, item.name)">
                                                <div v-if="item.image" class="avatar">
                                                    <img class="qualificationImg" :src="item.image">
                                                </div>
                                                <i v-else
                                                   :style="{backgroundImage: uploadItemBg, backgroundSize: 'cover'}"
                                                   class="el-icon-plus avatar-uploader-icon "></i>
                                            </el-upload>
                                            <div>
                                                <template v-if="item.is_must">
                                                    <span style="color: red;margin-right: 4px">*</span>
                                                </template>
                                                <span>{{ item.name }}</span>
                                            </div>
                                            <template v-if="item.template_url">
                                                <div class="downLoadTemplate"
                                                     @click.stop="downLoadTemplate(item.template_url)">
                                                    下载模板
                                                </div>
                                            </template>
                                        </div>
                                        <div>
                                            <template v-if="item.status !== -2 && auditStatus(item.status).passFail">
                                                <div style="text-align: left;align-self: flex-start">
                                                    <span>审核状态：</span>
                                                    <span
                                                        :style="{color:auditStatus(item.status).color}">{{ auditStatus(item.status).words
                                                        }}</span>
                                                </div>
                                            </template>
                                            <template v-if="item.expire_prompt === 3">
                                                <div style="color: red">
                                                    <span style="color: red;margin-right: 5px">*</span>
                                                    <span>已过期，急需上传新证件并更新资质信息</span>
                                                </div>
                                            </template>
                                            <template>
                                                <div style="display: flex;align-items: center">
                                                    <span>有效期&emsp;&emsp;&nbsp;&nbsp;</span>
                                                    <el-radio-group v-model="item.long_term">
                                                        <el-radio :label="0">效期</el-radio>
                                                        <el-radio :disabled="item.is_need_long_time === 0" :label="1">长期
                                                        </el-radio>
                                                    </el-radio-group>
                                                </div>
                                            </template>
                                            <div v-show="item.long_term === 0">
                                                <div style="margin: 6px 0;">
                                                    <span class="label-title">开始日期</span>
                                                    <el-date-picker
                                                        size="small"
                                                        value-format="yyyy-MM-dd"
                                                        v-model="item.expiration_start_date"
                                                        type="date"
                                                        @change="checkDates(item.expiration_start_date,item.expiration_end_date,index)"
                                                        placeholder="选择开始日期">
                                                    </el-date-picker>
                                                </div>
                                                <div>
                                                    <span class="label-title">结束日期</span>
                                                    <el-date-picker
                                                        value-format="yyyy-MM-dd"
                                                        size="small"
                                                        v-model="item.expiration_end_date"
                                                        type="date"
                                                        @change="checkDates(item.expiration_start_date,item.expiration_end_date,index)"
                                                        placeholder="选择结束日期">
                                                    </el-date-picker>
                                                </div>
                                            </div>
                                            <template v-if="item.is_need_card_no == 1">
                                                <div style="display: flex;margin-top: 6px">
                                                    <span class="label-title">证件编号</span>
                                                    <el-input size="small" placeholder="请输入内容"
                                                              v-model="item.card_no">
                                                    </el-input>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </el-col>
                            </template>
                        </el-row>
                    
                    </div>
                </el-card>
            </template>
            <div style="margin-top: 10px;display: grid">
                <el-button type="primary" @click="onsubmit">提交</el-button>
            </div>
        </el-container>
    </div>
</template>

<script>
    import { qualificationConfig, qualificationUpload, memberQualification } from "@/api/member/info"
    
    export default {
        data() {
            return {
                uploadItemBg: `url(${this.$config.baseUrl}/upload/uniapp/new/icon/qualificationBg.png)`,
                qualificationList: [],
                imageUrl: ""
            }
        },
        async created() {
            await this.getQualificationConfig()
            await this.getMemberQualification()
        },
        methods: {
            checkDates(startDate, endDate, index) {
                if (startDate && endDate) {
                    let start = new Date(startDate)
                    let end = new Date(endDate)
                    if (start > end) {
                        this.$message.error("开始日期不能大于结束日期")
                        // 重置开始日期为结束日期
                        this.qualificationList[index]["expiration_end_date"] = "";
                    }
                }
            },
            downLoadTemplate(url) {
                window.location.href = url
            },
            auditStatus(status) {
                let audit = { passFail: true, words: "", color: "#2FBC6D" }
                switch (status) {
                    case 0:
                        audit.passFail = true
                        audit.words = "待审核"
                        audit.color = "#FF5A5A"
                        break
                    case 1:
                        audit.passFail = false
                        audit.words = "审核通过"
                        break
                    case -1:
                        audit.passFail = true
                        audit.words = "审核拒绝，待重新提交"
                        audit.color = "#FF5A5A"
                        break
                }
                return audit
            },
            async getMemberQualification() {
                const res = await memberQualification()
                // 更新全部资质数组的内容
                if (res.code === 0 && res.data.length) {
                    const memberQualificationList = res.data
                    // 需要更新的字段
                    let needUpdate = ["image", "card_no", "expiration_end_date", "expiration_start_date", "expire_prompt", "status", "long_term"]
                    this.qualificationList.forEach(ele => {
                        const index = memberQualificationList.findIndex(item => item.identify === ele.identify)
                        if (index !== -1) {
                            for (let i = 0; i < needUpdate.length; i++) {
                                ele[needUpdate[i]] = memberQualificationList[index][needUpdate[i]]
                            }
                        }
                    })
                }
            },
            async onsubmit() {
                // 提交信息
                let qualifications = this.qualificationList.map(ele => {
                    if (!ele.image) return false
                    return {
                        identify: ele.identify,//资质标识
                        expiration_start_date: ele.expiration_start_date,//开始时间
                        expiration_end_date: ele.expiration_end_date,//结束时间
                        long_term: ele.long_term,//长期有效  1是 0否
                        image: ele.image,
                        card_no: ele.card_no//证书号
                    }
                }).filter(Boolean)
                const res = await qualificationUpload({ qualifications })
                if (res.code === 0) {
                    this.$message({ message: "提交成功", type: "success" })
                    await this.getMemberQualification()
                } else {
                    this.$confirm(res.message, "提示", {
                        confirmButtonText: "确认"
                    })
                }
                
            },
            handleRemove(file, fileList, item) {
                item.image = ""
                
            },
            handleAvatarSuccess(res, file, item) {
                item.image = res.data.pic_path;
            },
            beforeAvatarUpload(file,name) {
                const isLt2M = file.size / 1024 / 1024 < 2
                if (!isLt2M) {
                    this.$message.error(`上传${name}图片大小不能超过 2MB!`)
                }
                return isLt2M
            },
            async getQualificationConfig() {
                const res = await qualificationConfig()
                if (res.code === 0) {
                    this.qualificationList = res.data
                    this.qualificationList.forEach((ele) => {
                        this.$set(ele, "image", "") //图片地址
                        this.$set(ele, "expiration_start_date", "")//开始时间
                        this.$set(ele, "expiration_end_date", "")//结束时间
                        this.$set(ele, "long_term", ele.is_need_long_time)//长期有效  1是 0否
                        this.$set(ele, "card_no", "")//证书号
                        this.$set(ele, "expire_prompt", "")//expire_prompt =1正常, expire_prompt=2 近3个月, expire_prompt=3 已过期
                        this.$set(ele, "status", -2)//审核状态 0待审核 1审核通过  -1审核拒绝
                    })
                } else {
                    this.$message({
                        message: res.message,
                        type: "warning"
                    })
                }
            }
        },
        computed: {
            // 必须传递的资质
            requisiteQualification() {
                return this.qualificationList.filter(ele => ele.is_must === 1)
            },
            // 非必须资质
            nonEssentialQualification() {
                return this.qualificationList.filter(ele => ele.is_must !== 1)
            },
            qualificationGroup() {
                let arr = []
                let requisiteQualification = {
                    _id: 1,
                    type: "必上传资质",
                    qualificationList: this.requisiteQualification
                }
                let nonEssentialQualification = {
                    _id: 2,
                    type: "非必上传资质",
                    qualificationList: this.nonEssentialQualification
                }
                arr.push(requisiteQualification, nonEssentialQualification)
                return arr
            }
        }
    }
</script>
<style lang="scss">
    .downLoadTemplate {
        color: $base-color;
    }
    
    .downLoadTemplate:hover {
        font-family: monospace;
        text-decoration: underline;
        cursor: alias;
    }
    
    .avatar-uploader .el-upload {
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 230px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }
    
    .avatar {
        border: 1px dashed #d9d9d9;
        width: 230px;
        height: 150px;
        display: block;
        
        .qualificationImg {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }
</style>
<style lang="scss" scoped>
    .content {
        width: 100%;
    }
    
    .el-card:first-of-type {
        margin-bottom: 10px;
    }
    
    .type-title {
        font-size: 18px;
        font-weight: bold;
        
        .mustIcon {
            color: red;
            margin-right: 6px;
        }
    }
    
    .uploadQualification {
        .qualificationUploadItem {
            margin-bottom: 20px;
        }
    }
    
    .label-title {
        flex-shrink: 0;
        display: inline-block;
        width: 80px;
    }
</style>
